import { Box, CircularProgress, Fab } from "@mui/material";
import React from "react";
import { numberFormat } from "../utils/text";
import theme from "../theme";

export interface FabProgressProps {
  progress: number;
}

function FabProgress(props: FabProgressProps) {
  const { progress } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Fab>
          { numberFormat(progress * 100) + '%' }
        </Fab>
        <CircularProgress
          size={68}
          variant="determinate"
          value={progress * 100}
          sx={{
            color: theme.palette.secondary.main,
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
}

export default FabProgress;
