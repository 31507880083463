import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import MapView from './pages/MapView';
import { APIProvider } from '@vis.gl/react-google-maps';
import { LeftMenuContext, LeftMenuContextProps } from './components/LeftMenu';
import DroneView from './pages/DroneView';
import { PipContext, PipContextProps } from './components/Pip';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { PositionContext, defaultPositionManager } from './contexts/position';
import { DroneContext } from './contexts/drone';
import { OrganizationContext } from './contexts/organization';

function App() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyD68kpit7GVfUExBXN8EwiKmBH9pk-34lA';

  const [ leftMenuOpen, setLeftMenuOpen ] = React.useState<boolean>(false);
  const leftMenuCtxValue: LeftMenuContextProps = { open: leftMenuOpen, setOpen: setLeftMenuOpen };

  const [ pipPage, setPipPage ] = React.useState<'map' | 'drone'>('drone');
  const pipCtxValue: PipContextProps = { page: pipPage, setPage: setPipPage };

  const [ selectedDroneID, setSelectedDroneID ] = React.useState<string | null>(null);
  const droneCtxValue = { droneID: selectedDroneID, setDroneID: setSelectedDroneID };

  const [ selectedOrganizationID, setSelectedOrganizationID ] = React.useState<string | null>(localStorage.getItem('currentOrganizationId'));
  const organizationCtxValue = { organizationID: selectedOrganizationID, setOrganizationID: setSelectedOrganizationID };

  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

  const positionManager = defaultPositionManager;

  // automatically sign-in
  React.useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  // import active drone and organization from url
  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const url = new URL(window.location.href);
    const droneID = url.searchParams.get('drone');
    const organizationID = url.searchParams.get('organization');

    if (organizationID) {
      setSelectedOrganizationID(organizationID);
    }
    if (droneID) {
      setSelectedDroneID(droneID);
    }

    if (droneID || organizationID) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [auth.user]);

  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const currentOrganizationId = localStorage.getItem('currentOrganizationId');

    if (selectedOrganizationID) {
      localStorage.setItem('currentOrganizationId', selectedOrganizationID);
    }

    if (currentOrganizationId !== selectedOrganizationID) {
      window.location.reload();
    }
  }, [selectedOrganizationID, auth.user]);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  return (
    <APIProvider apiKey={apiKey}>
      <PositionContext.Provider value={positionManager}>
        <OrganizationContext.Provider value={organizationCtxValue}>
          <DroneContext.Provider value={droneCtxValue}>
            <PipContext.Provider value={pipCtxValue}>
              <LeftMenuContext.Provider value={leftMenuCtxValue}>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Layout />}>
                      <Route index element={<MapView />} />
                      <Route path="map" element={<MapView />} />
                      <Route path="drone" element={<DroneView />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </LeftMenuContext.Provider>
            </PipContext.Provider>
          </DroneContext.Provider>
        </OrganizationContext.Provider>
      </PositionContext.Provider>
    </APIProvider>
  );
}

export default App;
