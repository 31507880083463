import { Chip } from "@mui/material";
import React from "react";
import { DroneStatus } from "../grpc/ui_pb";
import useApi from "../hooks/useApi";
import { getTextColor } from "../utils/color";
import useInterval from "../hooks/useInterval";
import { useTranslation } from "react-i18next";
import Notification from "./Notification";

export interface DroneStatusBadgeProps {
  droneID: string | null | undefined;
}

function DroneStatusBadge(props: DroneStatusBadgeProps) {
  const { droneID } = props;

  const [ droneStatus, setDroneStatus ] = React.useState<DroneStatus.AsObject | null>(null);
  const [ ticker, setTicker ] = React.useState<number>(0);
  const [ notificationOpen, setNotificationOpen ] = React.useState<boolean>(false);

  const { get } = useApi();
  const { t } = useTranslation();

  const color = (): string => {
    switch (droneStatus?.metrics?.state + '') {
      default: case 'UNKNOWN': case 'OTHER':
        return '#e0e0e0';
      case 'LANDED':
        return '#fbc02d';
      case 'HOVERING':
        return '#f57c00';
      case 'FLYING':
        return '#689f38';
      case 'FLIGHT_PLAN':
        return '#00796b';
      case 'DOWNLOADING':
        return '#90a4ae';
    }
  }

  const showStreamNotification = (): boolean => {
    if (!droneStatus?.metrics?.liveStreamState) {
      return false;
    }
    if (droneStatus?.metrics?.liveStreamState+'' === 'STREAM_CONNECTED') {
      return false;
    }
    if (droneStatus?.metrics?.liveStreamState+'' === 'STREAM_NOT_CONNECTED') {
      return false;
    }
    if (droneStatus?.metrics?.liveStreamState+'' === 'STREAM_UNKNOWN') {
      return false;
    }
    return true;
  }

  useInterval(() => {
    setTicker(ticker + 1);
  }, 1000);

  React.useEffect(() => {
    if (!droneID) {
      setDroneStatus(null);
      return;
    }
    get(`/drones/${droneID}/status`).then((res: any) => {
      if (res) {
        setDroneStatus(res);
        setNotificationOpen(showStreamNotification());
      }
    });
  }, [droneID, ticker]);

  if (!droneStatus) {
    return (
      <React.Fragment>
        <Chip label={t("UNKNOWN")} style={{backgroundColor: color(), color: getTextColor(color())}} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Chip label={t(droneStatus.metrics?.state+'')} style={{backgroundColor: color(), color: getTextColor(color())}} />
      <Notification open={notificationOpen} text={t('Live stream error, reconnecting...')} />
    </React.Fragment>
  );
}

export default DroneStatusBadge;
