import React from "react";
import OvenPlayer from 'ovenplayer';
import { DroneContext } from "../contexts/drone";
import useApi from "../hooks/useApi";
import useInterval from "../hooks/useInterval";
import useSelectedDrone from "../hooks/useSelectedDrone";

export interface PlayerProps {
  id: string;
  options?: OvenPlayer.OvenPlayerConfig;
  onClicked?: (e: PointerEvent) => void;
}

function Player(props: PlayerProps) {
  const [ player, setPlayer ] = React.useState<OvenPlayer.OvenPlayerInstance | null>(null);
  const [ streamURL, setStreamURL ] = React.useState<string | null>(null);
  const [ errored, setErrored ] = React.useState<boolean>(false);
  const [ ticker, setTicker ] = React.useState<number>(0);

  const droneCtx = React.useContext(DroneContext);

  const ref = React.useRef(null);

  const { selectedDroneStatus } = useSelectedDrone();
  const { get, post } = useApi();

  useInterval(() => {
    setTicker(ticker + 1);
  }, 1000);

  const startLiveStream = () => {
    if (!droneCtx?.droneID) {
      return;
    }

    if (selectedDroneStatus && selectedDroneStatus?.metrics?.liveStreamState+'' === 'STREAM_NOT_CONNECTED') {
      const data = {
        //droneId: droneCtx.droneID,
        cameraSettings: {
          cameraType: 'NORMAL',
          mediaResolution: 'RESOLUTION_DEFAULT',
          videoFrameRate: 'FRAME_RATE_DEFAULT',
          photoFormat: 'PHOTO_FORMAT_DEFAULT',
          videoFormat: 'VIDEO_FORMAT_DEFAULT',
          photoMode: 'PHOTO_MODE_SINGLE',
          videoMode: 'VIDEO_MODE_NORMAL',
        },
      }

      post(`/drones/${droneCtx.droneID}/livestream/start`, data).then((res: any) => {});
    }
  };

  React.useEffect(() => {
    setErrored(false);
    setStreamURL(null);
  }, [droneCtx?.droneID]);

  React.useEffect(() => {
    startLiveStream();
  }, [droneCtx?.droneID, ticker]);

  React.useEffect(() => {
    if (!droneCtx?.droneID) {
      return;
    }

    get(`/drones/${droneCtx.droneID}/livestream?cameraSettings.cameraType=NORMAL`).then((res: any) => {
      if (res && res.url) {
        setStreamURL(res.url);
        setErrored(false);
      } else {
        setStreamURL(null);

        try {
          if (player) {
            player.remove();
          }
        } catch (e) {
          // ignore
        }
      }
    });
  }, [droneCtx?.droneID, ticker, errored]);

  React.useEffect(() => {
    try {
      if (player) {
        player.remove();
      }
    } catch (e) {
      // ignore
    }

    if (!streamURL || errored) {
      return;
    }

    const opt = props.options ? Object.assign({}, props.options) : {};
    opt.autoStart = true;
    opt.controls = false;
    opt.expandFullScreenUI = false;
    opt.mute = true;
    opt.showBigPlayButton = false;
    opt.disableSeekUI = true;
    opt.showSeekControl = false;
    opt.timecode = false;
    opt.playbackRates = [1];
    opt.hidePlaylistIcon = true;
    opt.autoFallback = true;
    opt.sources = [{
      type: 'webrtc',
      file: streamURL,
    }];

    let tooMuch = false;
    setTimeout(() => {
      tooMuch = true;
    }, 3000);

    const i = setInterval(() => {
      if (tooMuch) {
        clearInterval(i);
        return;
      }

      if (!ref || !ref.current || !(ref.current as any).id) {
        return;
      }

      const id = (ref.current as any).id;
      const el = document.getElementById(id);
      if (el) {
        clearInterval(i);

        const pl = OvenPlayer.create(id, opt);
        pl.setAutoQuality(true);
        pl.on('clicked', (e: any) => {
          if (props.onClicked) {
            props.onClicked(e);
          }
        });
        pl.on('stateChanged', (e: any) => {
          if (e && e.newstate === 'error') {
            setErrored(true);
          } else if (e && e.newstate === 'playing') {
            setErrored(false);
          }
        });
        pl.play();

        setPlayer(pl);
      }
    }, 100);
  }, [props.id, ref, streamURL, errored]);

  return (
    <div id="player-container" style={{
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      height: '100%',
    }}>
      <div id={props.id} ref={ref} style={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        height: '100%',
      }} />
    </div>
  );
}

export default Player;
