import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#1771F1',
    },
    secondary: {
      main: '#FFAA00',
    },
    error: {
      main: red['500'],
    }
  },
});
  

export default theme;
