import { Snackbar } from '@mui/material';
import React from 'react';

export interface NotificationProps {
  open: boolean;
  text: string;
  handleClose?: () => void;
}

function Notification(props: NotificationProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={props.open}
      onClose={props.handleClose}
      message={props.text}
    />
  );
}

export default Notification;
