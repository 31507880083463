import React from "react";
import useApi from "../hooks/useApi";
import { Drone, DroneStatus } from "../grpc/ui_pb";
import { Marker } from "@vis.gl/react-google-maps";
import { coordinatesToLatLng } from "../utils/position";
import useInterval from "../hooks/useInterval";
import { DroneContext } from "../contexts/drone";
import { Coordinates } from "../grpc/common_pb";

export interface DronesPositionProps {
  onSelectedDronePositionChange?: (position: Coordinates.AsObject) => void;
}

function DronesPosition(props: DronesPositionProps) {
  const [ drones, setDrones ] = React.useState<Drone.AsObject[]>([]);
  const [ droneStatuses, setDroneStatuses ] = React.useState<DroneStatus.AsObject[]>([]);
  const [ ticker, setTicker ] = React.useState<number>(0);

  const droneCtx = React.useContext(DroneContext);

  const { get } = useApi();

  useInterval(() => {
    setTicker(ticker + 1);
  }, 1000);

  React.useEffect(() => {
    get('/drones').then((res: any) => {
      if (res && res.drones) {
        setDrones(res.drones);
      }
    });
  }, []);

  React.useEffect(() => {
    Promise.all(drones.map((drone: Drone.AsObject) => {
      return get(`/drones/${drone.id}/status`);
    })).then((res: any[]) => {
      setDroneStatuses(res);
    }).catch((err: any) => {
      console.log(err);
    });
  }, [drones, ticker]);

  return (
    <React.Fragment>
      { droneStatuses.map((droneStatus: DroneStatus.AsObject, index: number) => {
        if (!droneStatus.metrics?.position) {
          return null;
        }

        if (drones[index].id === droneCtx?.droneID) {
          if (props.onSelectedDronePositionChange) {
            props.onSelectedDronePositionChange(droneStatus.metrics.position);
          }
        }

        return (
          <React.Fragment key={'drone-fragment-'+drones[index].id}>
            { drones[index].id === droneCtx?.droneID &&
            <Marker key={'drone-home-marker-'+drones[index].id} position={coordinatesToLatLng(droneStatus.metrics!.homePosition!)}
            icon={{
              fillColor: '#1771F1',
              fillOpacity: 1,
              scale: 1.2,
              path: 'M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z',
              strokeColor: 'white',
              strokeWeight: 2,
              anchor: new google.maps.Point(15, 15),
            }} />
            }
            <Marker key={'drone-marker-'+drones[index].id} position={coordinatesToLatLng(droneStatus.metrics!.position!)}
            onClick={() => {
              droneCtx?.setDroneID(drones[index].id);
            }}
            icon={{
              fillColor: drones[index].id === droneCtx?.droneID ? '#1771F1' : '#B2B2B2',
              fillOpacity: 1,
              scale: 2,
              path: 'M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z',
              rotation: droneStatus.metrics?.position.heading,
              strokeColor: 'white',
              strokeWeight: 2,
              anchor: new google.maps.Point(15, 15),
            }} />
          </React.Fragment>
        );
      }) }
    </React.Fragment>
  );
}

export default DronesPosition;
