import React from "react";
import { useLocation } from "react-router-dom";

export default function useEmbed() {
  const [embed, setEmbed] = React.useState<boolean>(false);

  const location = useLocation();

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const embed = url.searchParams.get('embed');
    setEmbed(embed === 'true');
  }, [location]);

  return { embed };
}
