import { Avatar, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import React from "react";
import FlightIcon from '@mui/icons-material/Flight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DashboardIcon from '@mui/icons-material/Dashboard';
import useApi from "../hooks/useApi";
import { Drone, DroneStatus, Organization } from "../grpc/ui_pb";
import DroneStatusBadge from "./DroneStatusBadge";
import { DroneContext } from "../contexts/drone";
import { useAuth } from "react-oidc-context";
import { stringAvatar } from "../utils/avatar";
import { OrganizationContext } from "../contexts/organization";
import CheckIcon from '@mui/icons-material/Check';
import { version } from "../config";
import { useTranslation } from "react-i18next";

export interface LeftMenuContextProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const LeftMenuContext = React.createContext<LeftMenuContextProps | null>(null);

function LeftMenu() {
  const leftMenuCtx = React.useContext(LeftMenuContext);
  const droneCtx = React.useContext(DroneContext);
  const organizationCtx = React.useContext(OrganizationContext);

  const [dronesOpen, setDronesOpen] = React.useState(true);
  const [drones, setDrones] = React.useState<Drone.AsObject[]>([]);
  const [organizations, setOrganizations] = React.useState<Organization.AsObject[]>([]);

  const [userOpen, setUserOpen] = React.useState(false);

  const auth = useAuth();

  const { get } = useApi();
  const { t } = useTranslation();

  const handleDronesClick = () => {
    setDronesOpen(!dronesOpen);
  };

  const handleUserClick = () => {
    setUserOpen(!userOpen);
  }

  React.useEffect(() => {
    get('/organizations').then((res: any) => {
      if (res && res.organizations) {
        setOrganizations(res.organizations);
      }
    });
  }, []);

  React.useEffect(() => {
    get('/drones').then((res: any) => {
      if (res && res.drones) {
        if (drones.length === 0 && res.drones.length > 0 && !droneCtx?.droneID) {
          // first retrieval, if only one drone is active, select it
          Promise.all(res.drones.map((d: Drone.AsObject) => {
            return get(`/drones/${d.id}/status`);
          })).then((res2: any[]) => {
            const active = res2.filter((r: DroneStatus.AsObject) => r.metrics?.state+'' !== 'UNKNOWN');
            if (active.length === 1) {
              res2.forEach((r: DroneStatus.AsObject, i: number) => {
                if (r.metrics?.state+'' !== 'UNKNOWN') {
                  droneCtx?.setDroneID(res.drones[i].id);
                }
              });
            }
          }).catch((err: any) => {
            console.log(err);
          });
        }
        setDrones(res.drones);
      }
    });
  }, [organizationCtx?.organizationID])

  return (
    <Drawer
      anchor="left"
      open={leftMenuCtx?.open}
      onClose={() => {leftMenuCtx?.setOpen(false)}}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="close menu"
          onClick={() => {leftMenuCtx?.setOpen(false)}}
          sx={{ mr: 2 }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <span style={{
          flexGrow: 1,
        }} />
        <img src="/logotipo.png" alt="logo" style={{
          height: 32,
          width: 'auto',
          maxWidth: '50%',
        }} />
      </Toolbar>
      <List
        sx={{ width: '100%', maxWidth: 270, bgcolor: 'background.paper' }}
        component="nav"
      >
        <ListItemButton onClick={handleDronesClick} alignItems="flex-start" style={{
          alignItems: 'center',
        }}>
          <ListItemIcon>
            <Avatar>
              <FlightIcon />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary={t("Fleet")} />
          {dronesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={dronesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            { drones.map((drone: Drone.AsObject) => {
              return <ListItemButton key={ drone.id } sx={{ pl: 4 }} alignItems="flex-start"
              onClick={() => {
                droneCtx?.setDroneID(drone.id);
              }}
              selected={ drone.id === droneCtx?.droneID } style={{
                alignItems: 'center',
              }}>
                <ListItemText primary={ drone.name } />
                <DroneStatusBadge droneID={ drone.id } />
              </ListItemButton>
            }) }
          </List>
        </Collapse>
        <ListItemButton href="https://stream.dromt.it" target="_blank" alignItems="flex-start" style={{
          alignItems: 'center',
        }}>
          <ListItemIcon>
            <Avatar>
              <DashboardIcon />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary={t("Dashboard")} />
        </ListItemButton>
        <ListItemButton onClick={handleUserClick} alignItems="flex-start" style={{
          alignItems: 'center',
        }}>
          <ListItemIcon>
            <Avatar {...stringAvatar(auth.user?.profile.name ?? auth.user?.profile.email ?? t('User'))} />
          </ListItemIcon>
          <ListItemText primary={auth.user?.profile.name ?? auth.user?.profile.email ?? t('User')} />
          {userOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={userOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            { organizations.map((org: Organization.AsObject) => {
              return <ListItemButton key={ org.id } sx={{ pl: 4 }} alignItems="flex-start" onClick={() => {
                droneCtx?.setDroneID(null);
                if (organizationCtx?.organizationID === org.id) {
                  organizationCtx.setOrganizationID(null);
                  localStorage.removeItem('currentOrganizationId');
                  window.location.reload();
                } else {
                  organizationCtx?.setOrganizationID(org.id);
                }
              }} style={{
                alignItems: 'center',
              }}>
                <ListItemText primary={ org.name } />
                { org.id === organizationCtx?.organizationID && <ListItemIcon style={{
                  justifyContent: 'end',
                }}>
                  <CheckIcon />
                </ListItemIcon> }
              </ListItemButton>
            }) }
            <Divider sx={{ ml: 4 }} />
            <ListItemButton href="/logout" sx={{ pl: 4 }} alignItems="flex-start" onClick={() => {
              localStorage.removeItem('currentOrganizationId');
              auth.signoutRedirect();
            }} style={{
              alignItems: 'center',
            }}>
              <ListItemText primary={t("Logout")} />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItem alignItems="flex-start" style={{
          alignItems: 'center',
        }}>
          <ListItemText secondary={t("version") + " " + version} />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default LeftMenu;
