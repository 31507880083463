import React from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import { apiURL } from "../config";

function joinPath(...paths: string[]) {
  const isHttp = paths[0].startsWith('http://');
  const isHttps = paths[0].startsWith('https://');
  if (isHttp) {
    paths[0] = paths[0].replace('http://', '');
    return 'http://'+paths.join('/').replace(/\/+/g, '/');
  }
  if (isHttps) {
    paths[0] = paths[0].replace('https://', '');
    return 'https://'+paths.join('/').replace(/\/+/g, '/');
  }
  return paths.join('/').replace(/\/+/g, '/');
}

function useApi<T>() {
  const auth = useAuth();

  const optionalHeaders = () => {
    let headers: any = {};

    const organizationID = localStorage.getItem('currentOrganizationId');

    if (organizationID) {
      headers['Current-Organization'] = organizationID;
    }

    return headers;
  }

  const get = React.useCallback((url: string): Promise<T> => {
    return new Promise<T>(async(resolve, reject) => {
      try {
        const resp = await axios.get(joinPath(apiURL, url), {
          withCredentials: false,
          headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            ...optionalHeaders(),
          },
        });

        resolve(resp.data);
      } catch (e) {
        reject(e);
      }
    });
  }, [auth]);

  const post = React.useCallback((url: string, data?: any): Promise<T> => {
    return new Promise<T>(async(resolve, reject) => {
      try {
        const resp = await axios.post(joinPath(apiURL, url), data, {
          withCredentials: false,
          headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            'Content-Type': 'application/json',
            ...optionalHeaders(),
          },
        });

        resolve(resp.data);
      } catch (e) {
        reject(e);
      }
    });
  }, [auth]);

  const put = React.useCallback((url: string, data?: any): Promise<T> => {
    return new Promise<T>(async(resolve, reject) => {
      try {
        const resp = await axios.put(joinPath(apiURL, url), data, {
          withCredentials: false,
          headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            'Content-Type': 'application/json',
            ...optionalHeaders(),
          },
        });

        resolve(resp.data);
      } catch (e) {
        reject(e);
      }
    });
  }, [auth]);

  const del = React.useCallback((url: string): Promise<T> => {
    return new Promise<T>(async(resolve, reject) => {
      try {
        const resp = await axios.delete(joinPath(apiURL, url), {
          withCredentials: false,
          headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            ...optionalHeaders(),
          },
        });

        resolve(resp.data);
      } catch (e) {
        reject(e);
      }
    });
  }, [auth]);

  return { get, post, put, del };
}

export default useApi;
