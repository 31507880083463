import React from 'react';
import { Map, MapControl, ControlPosition, MapCameraChangedEvent, MapCameraProps } from '@vis.gl/react-google-maps';
import Metrics from '../components/Metrics';
import Pip, { PipContext } from '../components/Pip';
import { Fab } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import DroneActionsButton from '../components/DroneActionsButton';
import { useNavigate } from 'react-router-dom';
import MenuButton from '../components/MenuButton';
import UserPosition from '../components/UserPosition';
import DronesPosition from '../components/DronesPosition';
import useMapCenter from '../hooks/useMapCenter';
import useEmbed from '../hooks/useEmbed';

function MapView() {
  const pipCtx = React.useContext(PipContext);

  const [cameraState, setCameraState] = React.useState<MapCameraProps>({
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 3,
  });
  const [isLeft, setIsLeft] = React.useState<boolean>(true);
  const [isUp, setIsUp] = React.useState<boolean>(false);
  const [pipPosition, setPipPosition] = React.useState<(typeof ControlPosition)[keyof typeof ControlPosition]>(ControlPosition.LEFT_BOTTOM);

  const handleCameraChange = React.useCallback((ev: MapCameraChangedEvent) => {
    setCameraState(ev.detail);
  }, []);

  const navigate = useNavigate();
  const { center, setCurrentMapCenter, reCenter } = useMapCenter();
  const { embed } = useEmbed();

  React.useEffect(() => {
    if (isLeft && isUp) {
      setPipPosition(ControlPosition.LEFT_TOP);
    } else if (isLeft && !isUp) {
      setPipPosition(ControlPosition.LEFT_BOTTOM);
    } else if (!isLeft && isUp) {
      setPipPosition(ControlPosition.RIGHT_TOP);
    } else {
      setPipPosition(ControlPosition.RIGHT_BOTTOM);
    }
  }, [isLeft, isUp]);

  React.useEffect(() => {
    setCurrentMapCenter(cameraState.center);
  }, [cameraState.center]);

  React.useEffect(() => {
    if (center) {
      setCameraState({
        center,
        zoom: cameraState.zoom === 3 && cameraState.center.lat === 0 && cameraState.center.lng === 0 ? 18 : cameraState.zoom,
      });
    }
  }, [center]);

  return (
    <Map
      zoom={ cameraState.zoom }
      mapTypeId={'hybrid'}
      center={ cameraState.center }
      onCameraChanged={handleCameraChange}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <UserPosition />
      <DronesPosition />
      { !embed && <MapControl position={ControlPosition.TOP_LEFT}>
        <MenuButton />
      </MapControl> }
      { !embed && <MapControl position={ControlPosition.TOP_CENTER}>
        <Metrics />
      </MapControl> }
      { !embed && <MapControl position={ControlPosition.RIGHT_BOTTOM}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <DroneActionsButton />
          <Fab style={{
            margin: 8,
          }} onClick={reCenter}>
            <MyLocationIcon />
          </Fab>
        </div>
      </MapControl> }
      <MapControl position={pipPosition}>
        <Pip
          onSwipedLeft={() => {
            setIsLeft(true);
          }}
          onSwipedRight={() => {
            setIsLeft(false);
          }}
          onSwipedUp={() => {
            setIsUp(true);
          }}
          onSwipedDown={() => {
            setIsUp(false);
          }}
          onClick={() => {
            if (embed) return;
            navigate('/drone');
            pipCtx?.setPage('map');
          }}
          />
      </MapControl>
    </Map>
  );
}

export default MapView;
