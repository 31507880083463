import { Coordinates } from "../grpc/common_pb";

export function geolocationToLatLng(geolocation: GeolocationPosition): google.maps.LatLngLiteral {
  return {
    lat: geolocation.coords.latitude,
    lng: geolocation.coords.longitude,
  };
}

export function coordinatesToLatLng(coordinates: Coordinates.AsObject): google.maps.LatLngLiteral {
  return {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  };
}
