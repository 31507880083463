import React from "react";
import Pip, { PipContext } from "../components/Pip";
import { useNavigate } from "react-router-dom";
import Metrics from "../components/Metrics";
import DroneActionsButton from "../components/DroneActionsButton";
import MenuButton from "../components/MenuButton";
import Player from "../components/Player";
import useEmbed from "../hooks/useEmbed";

function DroneView() {
  const pipCtx = React.useContext(PipContext);

  const [isLeft, setIsLeft] = React.useState<boolean>(true);
  const [isUp, setIsUp] = React.useState<boolean>(false);
  const [pipStyle, setPipStyle] = React.useState<React.CSSProperties>({
    position: 'absolute',
    bottom: 10,
    left: 10,
  });

  const navigate = useNavigate();
  const { embed } = useEmbed();

  React.useEffect(() => {
    if (isLeft && isUp) {
      setPipStyle({
        position: 'absolute',
        top: 60,
        left: 10,
      });
    } else if (isLeft && !isUp) {
      setPipStyle({
        position: 'absolute',
        bottom: 10,
        left: 10,
      });
    } else if (!isLeft && isUp) {
      setPipStyle({
        position: 'absolute',
        top: 60,
        right: 10,
      });
    } else {
      setPipStyle({
        position: 'absolute',
        bottom: 80,
        right: 10,
      });
    }
  }, [isLeft, isUp]);

  return <div style={{
    backgroundColor: 'black',
    width: '100%',
    height: '100%',
  }}>
    { !embed && <MenuButton static /> }
    <Player id="view" />
    <Metrics style={
      {
        position: 'absolute',
        top: 8,
        right: 0,
        left: 65,
        textAlign: 'center',
      }
    }/>
    <Pip style={pipStyle}
      onSwipedLeft={() => {
        setIsLeft(true);
      }}
      onSwipedRight={() => {
        setIsLeft(false);
      }}
      onSwipedUp={() => {
        setIsUp(true);
      }}
      onSwipedDown={() => {
        setIsUp(false);
      }}
      onClick={() => {
        if (embed) return;
        navigate('/map');
        pipCtx?.setPage('drone');
      }}
      />
    <DroneActionsButton style={{
      position: 'absolute',
      bottom: 10,
      right: 10,
    }}/>
  </div>;
}

export default DroneView;
