import React from "react";
import useApi from "./useApi";
import useSelectedDrone from "./useSelectedDrone";

function useController() {
  const { post } = useApi();
  const { selectedDroneStatus } = useSelectedDrone();

  const goHome = (droneID: string): Promise<null> => {
    return new Promise((resolve, reject) => {
      const data = [];
      if (selectedDroneStatus?.metrics?.state+'' === 'FLIGHT_PLAN') {
        data.push({
          id: 23,
          blocking: true,
          empty: {},
        });
      } else if (selectedDroneStatus?.metrics?.state+'' === 'FLYING') {
        data.push({
          id: 22,
          blocking: true,
          empty: {},
        });
      }

      data.push({
        id: 3,
        blocking: true,
        returnHome: {
          altitude: 40,
          forceLand: true,
        },
      });
      post(`/controller/drone/${droneID}`, data).then(() => {
        resolve(null);
      }).catch((e) => {
        reject(e);
      });
    });
  };

  return {
    goHome,
  };
}

export default useController;
