import React from "react";

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = React.useRef<() => void>(() => {});

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => {
        clearInterval(id);
      }
    }
  }, [delay]);
}

export default useInterval;
