import React from "react";
import { Drone, DroneStatus } from "../grpc/ui_pb";
import { DroneContext } from "../contexts/drone";
import useInterval from "./useInterval";
import useApi from "./useApi";

function useSelectedDrone() {
  const [selectedDroneID, setSelectedDroneID] = React.useState<string | null>(null);
  const [selectedDrone, setSelectedDrone] = React.useState<Drone.AsObject | null>(null);
  const [selectedDroneStatus, setSelectedDroneStatus] = React.useState<DroneStatus.AsObject | null>(null);
  const [ticker, setTicker] = React.useState<number>(0);

  const droneCtx = React.useContext(DroneContext);

  const { get } = useApi();

  useInterval(() => {
    setTicker(ticker + 1);
  }, 1000);

  React.useEffect(() => {
    setSelectedDroneID(droneCtx?.droneID ?? null);
    setSelectedDrone(null);
    setSelectedDroneStatus(null);

    if (!droneCtx?.droneID) {
      return;
    }

    get(`/drones`).then((res: any) => {
      if (res && res.drones) {
        setSelectedDrone(res.drones.find((drone: Drone.AsObject) => drone.id === droneCtx?.droneID) ?? null);
      }
    });
  }, [droneCtx?.droneID]);

  React.useEffect(() => {
    if (!selectedDrone || !selectedDroneID) {
      setSelectedDroneStatus(null);
      return;
    }

    get(`/drones/${selectedDrone.id}/status`).then((res: any) => {
      if (res) {
        setSelectedDroneStatus(res);
      }
    });
  }, [selectedDrone, ticker]);

  return { selectedDroneID, selectedDrone, setSelectedDrone, selectedDroneStatus, setSelectedDroneStatus };
}

export default useSelectedDrone;
