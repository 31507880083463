import { Coordinates, Velocity } from "../grpc/common_pb";

export function velocityToSpeed(velocity: Velocity.AsObject | null | undefined): number {
  if (!velocity) {
    return 0;
  }
  return Math.sqrt(velocity.x * velocity.x + velocity.y * velocity.y + velocity.z * velocity.z);
}

export function distance(pos1: Coordinates.AsObject | null | undefined, pos2: Coordinates.AsObject | null | undefined): number {
  if (!pos1 || !pos2) {
    return 0;
  }
  const dx = pos1.latitude - pos2.latitude;
  const dy = pos1.longitude - pos2.longitude;
  const dz = pos1.altitude - pos2.altitude;
  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}
