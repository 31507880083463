import { Map, MapCameraChangedEvent } from '@vis.gl/react-google-maps';
import { Box, Fab, Paper, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import useSwipe from "../hooks/useSwipe";
import { useLocation } from 'react-router-dom';
import Player from './Player';
import UserPosition from './UserPosition';
import DronesPosition from './DronesPosition';
import useMapCenter from '../hooks/useMapCenter';
import MinimizeIcon from '@mui/icons-material/Minimize';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import useEmbed from '../hooks/useEmbed';

export interface PipContextProps {
  page: 'map' | 'drone';
  setPage: (page: 'map' | 'drone') => void;
}

export interface PipProps {
  onSwipedLeft?: () => void
  onSwipedRight?: () => void
  onSwipedUp?: () => void
  onSwipedDown?: () => void
  onClick?: () => void
  style?: React.CSSProperties
}

export const PipContext = React.createContext<PipContextProps | null>(null);

function Pip(props: PipProps) {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const [zoom, setZoom] = React.useState<number>(16.5);

  const pipCtx = React.useContext(PipContext);

  const swipeHandlers = useSwipe(props);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const { center, setCenter, setCurrentMapCenter } = useMapCenter();
  const { embed } = useEmbed();

  const collapse = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setCollapsed(true);
  };

  const zoomIn = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (zoom >= 20) {
      return;
    }
    setZoom(zoom + 0.25);
  }

  const zoomOut = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (zoom <= 1) {
      return;
    }
    setZoom(zoom - 0.25);
  }

  React.useEffect(() => {
    if (embed) {
      setCollapsed(true);
    }
  }, [embed]);

  React.useEffect(() => {
    if (location.pathname === '/' || location.pathname.startsWith('/map')) {
      pipCtx?.setPage('drone');
    } else if (location.pathname.startsWith('/drone')) {
      pipCtx?.setPage('map');
    }
  }, [location]);

  const style = props.style ? Object.assign({}, props.style) : {};
  style.margin = 8;
  style.width = matches ? 320 : 240;
  style.height = matches ? 240 : 185;
  style.backgroundColor = 'black';
  style.overflow = 'hidden';

  const collapseStyle: React.CSSProperties = {
    position: 'absolute',
    top: 8,
    right: 8,
  };
  if (pipCtx?.page === 'drone') {
    collapseStyle.marginTop = 8;
    collapseStyle.marginRight = 8;
  }

  if (collapsed) {
    if (embed) return null;
    return (
      <Box sx={{
        position: 'absolute',
        bottom: 10,
        left: 10,
      }}>
        <Fab onClick={() => {
          setCollapsed(false);
        }}>
          <PictureInPictureAltIcon />
        </Fab>
      </Box>
    );
  }

  return <div
    onTouchStart={swipeHandlers.onTouchStart}
    onTouchEnd={swipeHandlers.onTouchEnd}
    onTouchMove={swipeHandlers.onTouchMove}
    onMouseDown={swipeHandlers.onTouchStart}
    onMouseUp={swipeHandlers.onTouchEnd}
    onMouseMove={swipeHandlers.onTouchMove}
    onClick={props.onClick}
    >
    <Paper elevation={3} style={style}>
      { pipCtx?.page === 'drone' && <Player id="pip" onClicked={() => {
        if (props.onClick) {
          props.onClick();
        }
      }} /> }
      { pipCtx?.page === 'map' && <React.Fragment>
        <Map
          zoom={ zoom }
          mapTypeId={'hybrid'}
          center={center}
          onCenterChanged={(e: MapCameraChangedEvent) => {
            setCurrentMapCenter(e.map.getCenter()?.toJSON() ?? null);
          }}
          gestureHandling={'none'}
          disableDefaultUI={true}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <UserPosition />
          <DronesPosition onSelectedDronePositionChange={(position) => {
            if (position.latitude !== center?.lat || position.longitude !== center?.lng) {
              setTimeout(() => {
                setCenter({
                  lat: position.latitude,
                  lng: position.longitude,
                });
              }, 0);
            }
          }} />
        </Map>
        <Fab size="small" style={{
          position: 'absolute',
          bottom: 8,
          right: 8,
        }} onClick={zoomIn}>
          <ZoomInIcon />
        </Fab>
        <Fab size="small" style={{
          position: 'absolute',
          bottom: 8,
          right: 60,
        }} onClick={zoomOut}>
          <ZoomOutIcon />
        </Fab>
        <Fab size="small" style={collapseStyle} onClick={collapse}>
          <MinimizeIcon />
        </Fab>
      </React.Fragment>
      }
    </Paper>
  </div>;
}

export default Pip;
