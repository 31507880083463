import { Chip, Stack, Typography } from "@mui/material";
import React from "react";
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import HeightIcon from '@mui/icons-material/Height';
import SpeedIcon from '@mui/icons-material/Speed';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar';
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import FlightIcon from '@mui/icons-material/Flight';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import Wifi1BarIcon from '@mui/icons-material/Wifi1Bar';
import Wifi2BarIcon from '@mui/icons-material/Wifi2Bar';
import WifiIcon from '@mui/icons-material/Wifi';
import { DroneStatus } from "../grpc/ui_pb";
import useApi from "../hooks/useApi";
import DroneStatusBadge from "./DroneStatusBadge";
import { distance, velocityToSpeed } from "../utils/metrics";
import { numberFormat } from "../utils/text";
import { DroneContext } from "../contexts/drone";
import useInterval from "../hooks/useInterval";
import { LeftMenuContext } from "./LeftMenu";
import { useTranslation } from "react-i18next";
import useEmbed from "../hooks/useEmbed";

export interface MetricsProps {
  style?: React.CSSProperties;
}

function Metrics(props: MetricsProps) {
  const [droneStatus, setDroneStatus] = React.useState<DroneStatus.AsObject | null>(null);
  const [ticker, setTicker] = React.useState<number>(0);

  const droneCtx = React.useContext(DroneContext);
  const leftMenuCtx = React.useContext(LeftMenuContext);

  const { get } = useApi();
  const { t } = useTranslation();
  const { embed } = useEmbed();

  useInterval(() => {
    setTicker(ticker + 1);
  }, 1000);

  const iconStyle = {
    color: 'white',
  };
  const chipStyle = {
    margin: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
  };

  const style = props.style ? Object.assign({}, props.style) : {
    margin: 8,
    marginLeft: 65,
  };

  React.useEffect(() => {
    if (!droneCtx?.droneID) {
      setDroneStatus(null);
      return;
    }
    get(`/drones/${droneCtx.droneID}/status`).then((res: any) => {
      if (res) {
        setDroneStatus(res);
      }
    });
  }, [droneCtx?.droneID, ticker]);

  if (droneCtx?.droneID === null) {
    return (
      <React.Fragment>
        <div style={style}>
          <Chip label={t('No Drone Selected')} style={chipStyle} onClick={() => {
            if (embed) return;
            leftMenuCtx?.setOpen(true);
          }} />
        </div>
      </React.Fragment>
    );
  }

  let downlinkIcon = <SignalWifi0BarIcon style={iconStyle} />;
  switch (droneStatus?.metrics?.downlinkLevel+'') {
    case 'SIGNAL_UNKNOWN':
      downlinkIcon = <SignalWifi0BarIcon style={iconStyle} />;
      break;
    case 'SIGNAL_BAD':
      downlinkIcon = <Wifi1BarIcon style={iconStyle} />;
      break;
    case 'SIGNAL_OK':
      downlinkIcon = <Wifi2BarIcon style={iconStyle} />;
      break;
    case 'SIGNAL_GOOD':
      downlinkIcon = <WifiIcon style={iconStyle} />;
      break;
  }

  let gpsIcon = <SignalCellular0BarIcon style={iconStyle} />;
  switch (droneStatus?.metrics?.gpsLevel+'') {
    case 'GPS_UNKNOWN':
      gpsIcon = <SignalCellular0BarIcon style={iconStyle} />;
      break;
    case 'BAD':
      gpsIcon = <SignalCellularAlt1BarIcon style={iconStyle} />;
      break;
    case 'OK':
      gpsIcon = <SignalCellularAlt2BarIcon style={iconStyle} />;
      break;
    case 'GOOD':
      gpsIcon = <SignalCellularAltIcon style={iconStyle} />;
      break;
  }

  return <React.Fragment>
    <div style={style}>
      <Chip icon={<SpeedIcon style={iconStyle} />} label={numberFormat(velocityToSpeed(droneStatus?.metrics?.velocity)) + ' m/s'} style={chipStyle} />
      <Chip icon={<HeightIcon style={iconStyle} />} label={numberFormat(droneStatus?.metrics?.position?.altitude ?? 0, 2) + ' m'} style={chipStyle} />
      <Chip icon={<SocialDistanceIcon style={iconStyle} />} label={numberFormat(distance(droneStatus?.metrics?.controllerPosition, droneStatus?.metrics?.position)) + ' m'} style={chipStyle} />
      <span style={{
        margin: 2,
      }}>
        <DroneStatusBadge droneID={droneCtx?.droneID} />
      </span>
      <Chip icon={<SportsEsportsIcon style={iconStyle} />} label={
        <Stack direction="row" spacing={0} style={{textAlign: 'center', verticalAlign: 'middle'}}>
          <Typography variant="body2">{ droneStatus?.metrics?.controllerBattery ?? 0 }%</Typography>
          { downlinkIcon }
        </Stack>
      } style={chipStyle} />
      <Chip icon={<FlightIcon style={iconStyle} />} label={
        <Stack direction="row" spacing={0} style={{textAlign: 'center', verticalAlign: 'middle'}}>
          <Typography variant="body2">{ droneStatus?.metrics?.battery ?? 0 }%</Typography>
          { gpsIcon }
        </Stack>
      } style={chipStyle} />
    </div>
  </React.Fragment>;
}

export default Metrics;
