import React from "react";
import useSelectedDrone from "./useSelectedDrone";
import { PositionContext } from "../contexts/position";
import { filter, take } from "rxjs";

function useMapCenter() {
  const [positionCenter, setPositionCenter] = React.useState<google.maps.LatLngLiteral | null>(null);
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral | undefined>(undefined);
  const [currentMapCenter, setCurrentMapCenter] = React.useState<google.maps.LatLngLiteral | null>(null);

  const positionCtx = React.useContext(PositionContext);

  const [centerByDrone, setCenterByDrone] = React.useState<boolean>(false);
  const { selectedDroneID, selectedDroneStatus } = useSelectedDrone();

  const reCenter = () => {
    const pos = positionCtx?.getPosition();
    if (pos) {
      // ensure the center changed before re-apply it
      setCenter(currentMapCenter ?? center);
      setTimeout(() => {
        setCenter({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        });
      });
    }
  };

  React.useEffect(() => {
    const posSub = positionCtx?.watchPosition().pipe(
      filter((position: GeolocationPosition | null) => !!position),
      take(1),
    ).subscribe((position: GeolocationPosition | null) => {
      if (position) {
        setPositionCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
    });
    return () => {
      posSub?.unsubscribe();
    }
  }, []);

  React.useEffect(() => {
    if (positionCenter && !selectedDroneStatus?.metrics?.position) {
      setCenter(positionCenter);
    }
  }, [positionCenter]);

  React.useEffect(() => {
    setCenterByDrone(false);
  }, [selectedDroneID]);

  React.useEffect(() => {
    if (selectedDroneStatus && !centerByDrone) {
      setCenterByDrone(true);
      if (selectedDroneStatus.metrics?.position) {
        setCenter({
          lat: selectedDroneStatus.metrics.position.latitude,
          lng: selectedDroneStatus.metrics.position.longitude,
        });
      } else {
        setCenter(currentMapCenter ?? center);
      }
    }
  }, [selectedDroneStatus, selectedDroneStatus?.metrics?.position, centerByDrone]);

  return {
    center,
    setCenter,
    setCurrentMapCenter,
    reCenter,
  };
}

export default useMapCenter;
